<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import StatesData from "./states-data";
import AddState from "./add-state";

import {
  layoutComputed,
  authComputed,
  statesMethods,
  statesComputed,
} from "@/state/helpers";
/**
 * States component
 */
export default {
  page: {
    title: "States",
    meta: [{ name: "States" }],
  },
  components: {
    Layout,
    PageHeader,
    StatesData,
    AddState,
  },
  mounted() {
    this.retrieveStates();
  },
  data() {
    return {
      title: "titles.states.text",
      /* items: [
        {
          text: "Nextrack",
        },
        {
          text: this.$t("titles.states.text"),
          active: true,
        },
      ], */
      busy: false,
      loaderDashboard: false,

      statesData: [],
    };
  },
  computed: {
    ...layoutComputed,
    ...authComputed,
    ...statesComputed,

    items(){
      return [
        {
          text: "Nextrack",
        },
        {
          text: this.$t("titles.states.text"),
          active: true,
        },
      ]
    } ,
    userRole(){
      return this.loggedIn.user.role
    }
  },
  methods: {
    ...statesMethods,

    retrieveStates() {
      if (this.getStates.results) {
        this.statesData = this.getStates.results;
      } else {
        this.busy = true;
        this.getStatesBy({ limit: 100 }).then(() => {
          this.busy = false;
        });
      }
    },
    openAdd() {
      this.showModalAdd();
    },
    makeToast(title, msg, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    showModalAdd() {
      this.$bvModal.show("add-state-modal");
    },
    hideModalAdd() {
      this.$bvModal.hide("add-state-modal");
    },
    add(state) {
      this.addState(state)
        .then((state) => {
          this.makeToast(
            "Add state",
            state.name + " has been added",
            "success"
          );
        })
        .catch((error) => {
          this.makeToast("Add state", error, "danger");
        });
    },
  },
  watch: {
    getStates(newVal) {
      this.statesData = newVal.results;
    },
  },
  beforeDestroy() {},
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t(title)" :items="items" />
    <div v-if="['SYSADMIN', 'ADMIN', 'MANAGER'].includes(userRole)" class="row">
      <div class="col-12 text-right">
        <b-button type="button" class="mr-2" variant="info" @click="openAdd"
          >{{$t("buttons.addState.text")}}
        </b-button>
        <AddState @addState="add" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <StatesData v-bind:states="statesData" :busy="busy"/>
      </div>
    </div>
  </Layout>
</template>