<template>
  <div>
    <b-modal
      id="add-state-modal"
      ref="add-state-modal"
      size="md"
      @hidden="cancel"
      :title="$t('modals.addState.text')"
    >
      <!-- Name -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="name">{{$t("modals.name.text")}}</label>
          <b-form-input
            id="name"
            v-model="stateAdd.name"
            class="mb-1"
            :placeholder="$t('modals.name.text')"
            :state="err.name ? false : null"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ err.name }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <!-- isClosed -->
      <div class="row">
        <div class="col-12 col-lg-12 text-right">
          <b-form-checkbox
            id="isClosed"
            v-model="stateAdd.isClosed"
            name="isClosed"
            :value="true"
            :unchecked-value="false"
          >
          {{$t("modals.isClosed.text")}}
          </b-form-checkbox>
        </div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="row p-2">
          <div class="col-10 text-right p-1">
            <b-button variant="success" class="text-white" @click="add(ok)">
              {{$t("buttons.add.text")}}
            </b-button>
          </div>
          <div class="col-2 text-center p-1">
            <b-button @click="cancel()"> {{$t("buttons.cancel.text")}} </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  created() {},
  data() {
    return {
      stateAdd: {
        name: null,
        isClosed: false,
      },
      err: {
        name: null,
      },
    };
  },
  watch: {},
  methods: {
    cancel() {
      this.clear();
    },
    clear() {
      this.stateAdd = {
        name: null,
        isClosed: false,
      };
      this.err = {
        name: null,
      };
    },
    add(ok) {
      if (!this.stateAdd.name) this.err.name = this.$t("msgError.obligField.text");
      else this.err.name = "";

      if (!this.err.name) {
        ok();
        this.$emit("addState", this.stateAdd);
      }
    },
  },
};
</script>